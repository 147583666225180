import React from "react";
import "../styles/index.css";
import "../styles/Animations.css";
import '../styles/Fonts.css';
import "../styles/App.css";
import { Outlet, Link } from "react-router-dom";


class Home extends React.Component<any, any> {

	constructor(props: any) {
		super(props);

		this.state = {
			width: window.innerWidth,
			height: window.innerHeight,
			prevMovePos: 0,
		};

		this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
		window.addEventListener('resize', this.handleWindowSizeChange);
		this.animateHome = this.animateHome.bind(this);
		this.setupHomeAnimation = this.setupHomeAnimation.bind(this);
	}

	componentDidMount(): void {
		this.setupHomeAnimation();
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowSizeChange);
		document.removeEventListener('mousemove', this.animateHome);
	}

	handleWindowSizeChange() {
		this.setState({width: window.innerWidth, height: window.innerHeight});
	}

	setupHomeAnimation() {
		console.log(document);
		let el = document.getElementById("bushy");

		console.log(el)
		if (el) {
			document.addEventListener("mousemove", this.animateHome);
		}
	}

	animateHome(event: MouseEvent) {
		let possum = document.getElementById("bushy-possum");

		if (possum) {
			let mouseX = event.pageX;
			let mouseY = event.pageY;
			let image = possum.getBoundingClientRect();
			let imageX = (image.left + image.right) / 2;
			let imageY = (image.top + image.bottom) / 2;

			let distance = Math.sqrt(
				Math.pow(mouseX - imageX, 2) + Math.pow(mouseY - imageY, 2)
			);

			let movePos = ((distance / 2 - 143) / 135);
			let moveX = movePos * 28;
			let moveY = movePos * -5;
			let scale = (1 - 0.78) * movePos;

			if (movePos < 0 && movePos > -143 && movePos < this.state.prevMovePos) {
				 possum.style.transition = "";
				possum.style.transitionDelay = "";
				possum.style.transform = `translate(${moveX}%, ${moveY}%) scale(${1 + scale}, 1)`;
				this.setState({prevMovePos: movePos});
			}

			if (movePos > 0) {
				this.setState({prevMovePos: 0});
				possum.style.transition = "all 1s";
				possum.style.transitionDelay = "0.4s";
				possum.style.transform = `translateX(0px)`;
			}
		}
	}

	render() {
		let width = this.state.width;
		let height = this.state.height;

		let rem = Math.min(Math.max(0.011 * width, 8), 16);
		let fullSizeH1 = Math.min(
			0.8 * width / 5.4,
			(height - (4 * rem)) / 5);

		let fontSize = fullSizeH1;

		let widthBushy = 3.68;
		let widthProjects = 5.4;

		let d = Math.min(width * 0.80 - 100, height ** (1 / 0.92) - 500) ;
		let A = (width - d) / 2;

		if (widthProjects * fullSizeH1 > d) {
			// console.log("TOO SMALL!");
		}

		let fill: number = Math.ceil( width / (fontSize * widthBushy)) + 5;

		let bushy = <>B<span id="bushy">
			<span id="bushy-possum"/>U</span>SHY</>
		let about = <span id="about"><Link to="/about"> ABOUT </Link></span>
		let projects = <span id="projects"><Link to="/projects"> PROJECTS </Link></span>
		let shop = <span id="shop"><Link to="/shop"> SHOP </Link></span>

		let rows = Array(5);
		let possum = 0;
		for(let i = 0; i < 5; i++) {
			let repeat = Math.ceil(fill / 2) + 1;
			let span;
			let translate = -200;

			switch (i) {
				case 0:
					translate = (width - A) - (width - (repeat * widthBushy * fontSize)) + 0.18 * d + 100;
					possum = translate;
					span = bushy;
					break;
				case 1:
					translate = A - (repeat * widthBushy * fontSize);
					span = about
					break;
				case 2:
					translate =  (width - A) - (width - (repeat * widthBushy * fontSize));
					span = projects
					break;
				case 3:
					span = "";
					break;
				case 4:
					translate = A - (repeat * widthBushy * fontSize);
					span = shop;
					break;
			}

			rows[i] = <p key={i} className="t1" style={{transform: `translateX(${translate}px)`}}>
				{"BUSHY ".repeat(repeat)} {span} {"BUSHY ".repeat(repeat)}
			</p>

			if (i == 2 || i == 0) {
				rows[i] = <p key={i} className="t1" style={{transform: `translateX(${translate}px)`, alignSelf: "flex-end"}}>
					{"BUSHY ".repeat(repeat)} {span} {"BUSHY ".repeat(repeat)}
				</p>
			}
		}

		if (width < 820) {
			let temp = rows[3];
			rows[3] = rows[4];
			rows[4] = temp;
		}

		return <div id="homepage">
				{rows}
				{/* <img id="homepage-possum" style={{left: `${possum}px`}} src="/images/cut out 3 4.png"/> */}
			</div>
	}
}

export default Home;
