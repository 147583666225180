import React from "react";
import "../styles/Fonts.css";
import "../styles/App.css";
import Modal from "react-modal";
import { Outlet, Link } from "react-router-dom";
import withParams from "../withParams";
import { IoCloseOutline } from "react-icons/io5";
import axios from "axios";
import { API } from "../index";

Modal.setAppElement("#root");

class Projects extends React.Component<any, any> {

	constructor(props: any) {
		super(props);

		this.state = {
			modalIsOpen: false,
			projects: [
			],
			prevMovePos: 0
		}
		this.openModal = this.openModal.bind(this);
		this.animateProject = this.animateProject.bind(this);
		this.setupProjectAnimation = this.setupProjectAnimation.bind(this);
	}

	componentDidMount(): void {
		window.scrollTo(0, 0);
		axios.get(API + "project?_fields=acf,title&acf_format=standard")
			.then((response) => {
				let projects = [];
				if (response.status === 200) {
					for (let project of response.data) {
						projects.push(project.title.rendered.toLowerCase());
					}
				}
				projects.sort();
				this.setState({ projects: projects });
			});
		if (this.props.params.project !== undefined) {
			this.openModal(true);
		}
		this.setupProjectAnimation();
	}

	componentWillUnmount(): void {
		document.removeEventListener("mousemove", this.animateProject);
	}

	setupProjectAnimation() {
		console.log(document);
		let el = document.getElementById("project-possum");

		console.log(el)
		if (el) {
			document.addEventListener("mousemove", this.animateProject);
		}
	}

	animateProject(event: MouseEvent) {
		if (window.innerWidth < 820) {
			return;
		}
		let possum = document.getElementById("project-possum");

		if (possum) {
			let mouseX = event.pageX;
			let mouseY = event.pageY;
			let image = possum.getBoundingClientRect();
			let imageX = (image.left + image.right) / 2;
			let imageY = (image.top + image.bottom) / 2;

			let distance = Math.sqrt(
				Math.pow(mouseX - imageX, 2) + Math.pow(mouseY - imageY, 2)
			);

			let movePos = ((distance / 2 - 143) / 135);

			let moveY = movePos * -40;

			if (movePos < 0 && movePos > -143 && movePos < this.state.prevMovePos) {
				possum.style.transition = "";
				possum.style.transitionDelay = "";
				possum.style.transform = `translateY(${moveY}%)`;
				this.setState({prevMovePos: movePos}); // update the previous value of movePos
			}

			if (movePos > 0) {
				this.setState({prevMovePos: 0});
				possum.style.transition = "all 1s";
				possum.style.transitionDelay = "0.4s";
				possum.style.transform = `translateX(0px)`;
			}
		}
	}


	openModal(open: boolean) {
		this.setState({ modalIsOpen: open });
	}

	componentWillUpdate(
		nextProps: Readonly<any>,
		nextState: Readonly<any>,
		nextContext: any): void
	{
		if (nextProps.params.project === undefined && nextProps.params.project !== this.props.params.project) {
			this.openModal(false);
		}
	}

	render() {

		let projects = this.state.projects.map((item: string, key: number) => {
			let link = item.replaceAll(" ", "-");
			return <div className="t1" key={key}>
				<div className="t3" id="project-item">
					<Link to={link} onClick={() => { this.openModal(true) }}
					> {item} </Link></div></div>;
		});

		return <>
			<Modal
				className="Modal"
				overlayClassName="Backdrop"
				isOpen={this.state.modalIsOpen}
				onRequestClose={() => {
					this.openModal(false);
					this.props.navigate("/projects");
				}}
				contentLabel="Project"
			>
				<div id="x" className="row trailing x">
					<Link to="/projects"><IoCloseOutline /></Link>
				</div>
				<Outlet />
			</Modal>
			<div className="container">
				<section>
					<div className="container column leading top project-page">
						{projects}
					</div>
				</section>
			</div>
			<div id="project-possum">
				<img src="/images/possum.png"/>
			</div>
		</>
	}
}

export default withParams(Projects);
