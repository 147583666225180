import React from 'react';
import './styles/Fonts.css';
import './styles/App.css';
import { Outlet, Link } from "react-router-dom";
import withParams from './withParams';
import axios from 'axios';
import { API } from "./index";

const ThemeContext = React.createContext({
	darkMode: false,
	callback: () => { },
	socials: [],
	contacts: [],
});

class App extends React.Component<any, any> {

	constructor(props: any) {
		super(props);

		this.state = {
			darkMode: false,
			contacts: [],
			socials: [],
		};

		this.setDarkMode = this.setDarkMode.bind(this);
		this.toggleDarkMode = this.toggleDarkMode.bind(this);
		this.setDarkModePreference = this.setDarkModePreference.bind(this);
	}

	componentDidMount(): void {
		let darkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;
		let preference = localStorage.getItem("darkMode");

		if (preference === "true") {
			this.setDarkMode(true);
		} else if (preference === "false") {
			this.setDarkMode(false);
		} else {
			if (darkMode)
				this.setDarkMode(true);
			else
				this.setDarkMode(false);
		}

		axios.get(API + "options/all").then((response) => {
			if (response.status === 200) {
				this.setState({
					socials: response.data.socials,
					contacts: response.data.contact_item,
				})
			}
		});
	}

	setDarkMode(on: boolean) {
		if (on) {
			document.documentElement.style.setProperty("--primary", "#AEAEB4");
			document.documentElement.style.setProperty("--secondary", "#FFF");
			document.documentElement.style.setProperty("--tertiary", "#444");
			document.documentElement.style.setProperty("--quartary", "#AEAEB4");
			document.documentElement.style.setProperty("--background", "#000");
		} else {
			// if (CSS.supports("color", "color(display-p3 0 0 0)")) {
			// 	document.documentElement.style.setProperty("--primary", "color(display-p3 0.9490 0.2667 0.0196)");
			// // } else {
			document.documentElement.style.setProperty("--primary", "#F24405");

			// }
			document.documentElement.style.setProperty("--secondary", "#FFF");
			document.documentElement.style.setProperty("--tertiary", "#AAFDFD");
			document.documentElement.style.setProperty("--quartary", "#00FFFF");
			document.documentElement.style.setProperty("--background", "#FFF");
		}
		this.setState({ darkMode: on });
	}

	setDarkModePreference(on: boolean) {
		localStorage.setItem('darkMode', String(on));
	}

	toggleDarkMode() {
		let currentMode = this.state.darkMode;

		this.setDarkModePreference(!currentMode);
		this.setDarkMode(!currentMode);
	}

	render() {
		return (
			<div className="App" style={{
				backgroundColor: this.state.darkMode ? "var(--background)" : "var(--primary)",
				color: "var(--secondary)",
				overflow: "hidden",			}}>
				<ThemeContext.Provider value={{ darkMode: this.state.darkMode, callback: this.toggleDarkMode, socials: this.state.socials, contacts: this.state.contacts }}>
					<Outlet />
				</ThemeContext.Provider>
			</div>
		);
	}
}

export default withParams(App);
export { ThemeContext };
