import { useContext } from "react";
import { useParams, useNavigate, useLocation} from "react-router-dom";
import { ThemeContext } from "./App";


export default function withParams(Component: any) {
	return (props: any) =>
		<Component {...props}
			params={useParams()}
			navigate={useNavigate()}
			location={useLocation()}/>;
}

export function withContext(Component: any) {
	return (props: any) =>
		<Component {...props} context={useContext(ThemeContext)}/>;
}
