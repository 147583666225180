import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import Home from './components/Home';
import Construction from './components/Construction';
import About from './components/About';
import Projects from './components/Projects';
import ProjectView from './components/ProjectView';
import Shop from './components/Shop';
import Controls from './components/Controls';


import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";

let URL = "https://bushylandscapes.com.au";
let API = "https://bushylandscapes.com.au/wordpress/wp-json/wp/v2/";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);


root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App />}>
                    <Route element={<Controls/>}>
                        <Route path="about" element={<About/>}/>
                        <Route path="projects" element={<Projects/>}>
                            <Route path=":project" element={<ProjectView/>}/>
                        </Route>
                        <Route path="shop" element={<Shop/>}/>
                    </Route>
                    <Route index element={<Home height={window.innerHeight}/>} />
                </Route>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);


export {
    URL,
    API
}
