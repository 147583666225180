import React from "react";
import "../styles/Fonts.css";
import "../styles/App.css";
import { Outlet, Link } from "react-router-dom";
import withParams from "../withParams";

class Shop extends React.Component<any, any> {

	constructor(props: any) {
		super(props);
	}

	componentDidMount(): void {
		window.scrollTo(0, 0);
	}

	render() {
		return <section className="container column center" style={{"color": "white", height: "100%"}}>
				<h1 className="t2" id="coming-soon"> Coming Soon </h1>
		</section>
	}
}

export default withParams(Shop);
