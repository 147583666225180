import React from "react";

import "../styles/Fonts.css";
import "../styles/App.css";
import { Outlet, Link } from "react-router-dom";
import withParams from "../withParams";
import axios from "axios";
import { API } from "../index";


class ProjectView extends React.Component<any, any> {

	constructor(props: any) {
		super(props);

		this.state = {
			content: []
		}
	}

	componentDidMount(): void {
		// window.scrollTo(0, 0);
		axios.get(API + "project?_fields=acf&acf_format=standard&slug="
			+ this.props.params.project)
			.then((response) => {
				if (response.status === 200) {
					let content = [];
					let res = response.data[0]?.acf?.project_block;
					if (res) {
						for (let item of res) {
							if (item.image) {
								content.push({
									type: "image",
									srcset: `${item.image.sizes["2048x2048"]} 2048w, ${item.image.sizes["1536x1536"]} 1536w, ${item.image.sizes["large"]} 1024w, ${item.image.sizes["medium"]} 768w`,
									alt: item.image.alt
								});
							} else if (item.caption) {
								content.push({
									type: "text",
									src: item.caption,
								});
							} else if (item.video) {
								content.push({
									type: "video",
									src: item.video.url,
								});
							}
						}
						this.setState({ content: content });
					}
				}
			});
	}

	render() {
		let content = this.state.content.map((item: any, key: number) => {
			switch (item.type) {
				case "image":
					return <div key={key} className="project-image">
						{ key < 3 ? <img srcSet={item.srcset} alt={item.alt} />
						: <img loading="lazy" srcSet={item.srcset} alt={item.alt} />}
					</div>
				case "video":
					return <div key={key} className="project-video">
						<video controls>
							<source src={item.src} />
						</video>
					</div>
				case "text":
					return <div key={key} className="project-text">
						<p>
							{item.src}
						</p>
					</div>
				default:
					return
			}


		});

		return <div className="project-modal">
			{content}
		</div>
	}
}

export default withParams(ProjectView);
