import React from "react";
import "../styles/App.css";
import "../styles/Fonts.css";
import { Outlet, Link } from "react-router-dom";
import withParams, {withContext} from "../withParams";
import { IoCart } from 'react-icons/io5';
import Modal from "react-modal";
import Sidebar from "./Sidebar";

Modal.setAppElement("#root");

class Controls extends React.Component<any, any> {

	constructor(props: any) {
		super(props);
		this.state = {
			modalIsOpen: false,
			cartIsOpen: false,
		}
		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.openCart = this.openCart.bind(this);
		this.closeCart = this.closeCart.bind(this);
	}

	componentDidMount(): void {
		window.scrollTo(0, 0);
	}

	componentWillUnmount() {
		this.openModal(false);
	}

	async openModal(open: boolean) {
		if (!open) {
			await this.closeModal();
		}
		this.setState({ modalIsOpen: open, cartIsOpen: false });
	}

	async closeModal() {
		let el = document.getElementsByClassName("ModalSidebar")[0];
		if (el) {
			el.classList.add("close-ModalSidebar");
			return new Promise(resolve => setTimeout(resolve, 480));
		}
	}

	async openCart(open: boolean) {
		if (!open) {
			await this.closeCart();
		}
		this.setState({ cartIsOpen: open, modalIsOpen: false });
	}

	async closeCart() {
		let el = document.getElementsByClassName("ModalSidebarRight")[0];
		if (el) {
			el.classList.add("close-ModalSidebarRight");
			return new Promise(resolve => setTimeout(resolve, 500));
		}
	}


	render() {

		let about = this.props.location.pathname === "/about";

		let backgroundColor = about ? "var(--background)" : "var(--primary)";
		let color = "var(--primary)";

		if (this.props.context.darkMode) {
			backgroundColor = about ? "var(--background)" : "var(--background)";
			color = "var(--secondary)";
		}

		let controlColor = about ? "var(--primary)" : "var(--quartary)";

		return <>
				<Modal
					className="ModalSidebar"
					overlayClassName="Backdrop"
					isOpen={this.state.modalIsOpen}
					onRequestClose={() => { this.openModal(false) }}
					contentLabel="Project"
					preventScroll={true}
				>
					<Sidebar callback={this.openModal}/>
				</Modal>
				<Modal
					className="ModalSidebarRight"
					overlayClassName="Backdrop"
					isOpen={this.state.cartIsOpen}
					onRequestClose={() => { this.openCart(false) }}
					contentLabel="Project"
					preventScroll={true}
				>
					<Sidebar callback={this.openCart} cart={true}/>
				</Modal>
				<div className="" style={{
					backgroundColor: backgroundColor,
					color: color,
					width: "100%"
				}}>
					<header style={{ color: controlColor }}>
						<div id="headerRow" className="row top space">
							<div className="t2" id="bushy-logo" onClick={() => { this.openModal(true) }}>Bushy</div>
							<div className="row trailing top t2">
								<div className="pill" style={{ borderColor: controlColor }} onClick={()=>{
									this.props.context.callback();
								}}>
									<div className={
										this.props.context.darkMode ? "circle circle-right": "circle"
									} style={{ backgroundColor: controlColor }} />
								</div>
								<IoCart className="icon" onClick={() => { this.openCart(true) }}/>
							</div>
						</div>
					</header>
					<section className="outlet" style={{ alignItems: "center" }}>
						<Outlet />
					</section>
				</div>
			</>
		}
}

export default withContext(withParams(Controls));
