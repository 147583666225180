import React from "react";
import "../styles/Fonts.css";
import "../styles/App.css";
import { Outlet, Link } from "react-router-dom";
import withParams, { withContext } from "../withParams";
import { API } from "../index";
import axios from "axios";

class About extends React.Component<any, any> {

	constructor(props: any) {
		super(props);
		this.state = {
			acknowledgment: "",
			bushy_team: "",
			heading: [],
			text_1: "",
			text_2: "",
			images: [],
		}
	}

	componentDidMount(): void {
		window.scrollTo(0, 0);
		axios.get(API + "pages\?name=about\&_fields=acf\&acf_format=standard")
			.then((response) => {
				if (response.status === 200) {
					let content = response.data[0].acf;
					this.setState({
						acknowledgment: content.acknowledgment,
						bushy_team: content.bushy_team,
						heading: content.heading,
						text_1: content.text_1,
						text_2: content.text_2,
						images: content.images,
					});
				}
			});
	}

	render() {
		let contacts = this.props.context.contacts.map((item: any, key: number) => {
			if (item.link !== "") {
				return <h5 className="t5" key={key}><a href={item.link}>{item.text}</a></h5>
			}
			return <h5 className="t5" key={key}>{item.text}</h5>
		});

		let socials = this.props.context.socials.map((item: any, key: number) => {
			return <a className="t4" href={item.link} key={key}>{item.name}</a>
		});


		return <div id="about">
			<section className="row bottom">
				<div className="container row">
					<div className="col1 row">
						<div className="col2 column top textbox">
							<div className="column top">
								{this.state.heading.length === 3 ?
									<>
										<h1 className="t3"
											style={{
												WebkitTextStrokeColor: "var(--primary)"
											}}>
											{this.state.heading[0].word} </h1>
										<h1 className="t3"
											style={{
												WebkitTextStrokeColor: "var(--primary)"
											}}>
											{this.state.heading[1].word} </h1>
										<h1 className="t3"
											style={{
												alignSelf: "flex-end",
												WebkitTextStrokeColor: "var(--primary)"
											}}>
											{this.state.heading[2].word} </h1> </>
									: <div />}
							</div>
						</div>
						<div className="col1 shrink" />
					</div>
				</div>
				<div className="container col1">
					<img className="grid-row"
						width="2560" height="1696"
						alt={this.state.images[0]?.alt}
						src={this.state.images[0]?.sizes.medium_large} />
				</div>
			</section>
			<section className="row space top">
				<div className="container column">
					<div className="row">
						<div id="text-1" className="col2 column leading textbox"
							dangerouslySetInnerHTML={{ __html: this.state.text_1 }}>
						</div>
						<div className="col1 shrink" />
					</div>
					<div className="col1 row top" style={{width: "100%"}}>
						<div className="col1">
							<img className="grid-row"
								alt={this.state.images[2]?.alt}
								src={this.state.images[2]?.sizes.medium_large} />
						</div>
						<div className="col1 shrink" />
						<div className="col1">
							<div className="grid-row"
								style={{
									backgroundColor: "var(--tertiary)",
									marginBottom: "var(--padding-sm)"
								}}>
								<img id="a123" className="grid-row"
									alt={this.state.images[1]?.alt}
									src={this.state.images[1]?.sizes.medium_large} />
								<img id="b124" className="grid-row"
									width="512" height="512"
									alt="Sketch of Possum"
									src="/images/possum-sketch.png" />
							</div>
						</div>
					</div>
					<div className="col2" id="image3">
						<img className="grid-row"
							loading="lazy"
							alt={this.state.images[3]?.alt}
							src={this.state.images[3]?.sizes.medium_large} />
					</div>
				</div>
				<div className="container row">
					<div className="col1 shrink" />
					<div className="col2 textbox" id="text-2"
						dangerouslySetInnerHTML={{ __html: this.state.text_2 }}>
					</div>
				</div>
			</section>
			<section className="row space wrap-reverse">
				<div className="container row">
					<div className="col2 center team textbox">
						<h2 className="t4"
							style={{ WebkitTextStrokeColor: "var(--primary)" }}>
							{this.state.bushy_team} </h2>
						<h5><a href="mailto:INFO@BUSHYLANDSCAPES.COM.AU">
							Join the team &gt;
						</a></h5>
					</div>
					<div className="col1 shrink" />
				</div>
				<div className="container row">
					<div className="col2">
						<img className="grid-row"
							loading="lazy"
							alt={this.state.images[4]?.alt}
							src={this.state.images[4]?.sizes.medium_large} />
					</div>
				</div>
			</section>
			<section className="row space top">
				<div className="container row">
					<div className="col2 shrink" />
					<div className="col1">
						<img className="grid-row"
							loading="lazy"
							alt={this.state.images[5]?.alt}
							src={this.state.images[5]?.sizes.medium_large} />
					</div>
				</div>
				<div className="container row">
					<div className="col2 textbox">
						<p> {this.state.acknowledgment} </p>
					</div>
					<div className="col1 shrink" />
				</div>
			</section>
			<section className="column center"
				style={{ padding: "var(--padding-lg)" }}>
				<div className="social column"
					style={{ padding: "var(--padding-md)" }}>
					{socials}
				</div>
				<div className="contact">
					{contacts}
				</div>
				<img loading="lazy" alt="secateurs" id="secateurs" src="/images/secateurs.png" />
			</section>
		</div>
	}
}

export default withContext(withParams(About));
