import React from "react";
import "../styles/Fonts.css";
import "../styles/App.css";

import { Outlet, Link } from "react-router-dom";
import withParams, {withContext} from "../withParams";
import {IoLogoFacebook, IoLogoLinkedin, IoLogoInstagram, IoLogoTiktok, IoCloseOutline} from "react-icons/io5";
import {FaXTwitter} from "react-icons/fa6";

class Sidebar extends React.Component<any, any> {

	constructor(props: any) {
		super(props);
	}

	componentDidMount(): void {
	}

	componentDidUpdate(prevProps: any): void {
		this.props.callback(false);
	}

	render() {
		if (this.props.cart) {
			return <div id="cart" className="column">
				<div className="container row top space" style={{width: "100%", padding: "var(--padding-sm)", boxSizing: "border-box"}}>
					<div className="t2">Cart</div>
					<IoCloseOutline className="x" onClick={()=>{this.props.callback(false)}}/>
				</div>
				<div className="row top">
					<h2 className="t2" style={{color: "var(--primary)"}}> Coming Soon </h2>
				</div>
			</div>
		}
		let contacts = this.props.context.contacts.map((item: any, key: number) => {
			if (item.link === "") {
				return <p className="tp" key={key}>{item.text}</p>
			}
			return <a className="tp" href={item.link} key={key}>{item.text}</a>
		});

		let socials = this.props.context.socials.map((item: any, key: number) => {
			let icon = <div/>
			switch (item.icon) {
				case "IoLogoTwitter":
					icon = <FaXTwitter/>;
					break;
				case "IoLogoInstagram":
					icon = <IoLogoInstagram/>;
					break;
				case "IoLogoTiktok":
					icon = <IoLogoTiktok/>;
					break;
				case "IoLogoFacebook":
					icon = <IoLogoFacebook/>;
					break;
				case "IoLogoLinkedin":
					icon = <IoLogoLinkedin/>;
					break;
			}
			return <a href={item.link}>{icon}</a>
		});


		return <section className="sidebar container row space top" style={{color: "var(--primary)", height: "100%"}}>
			<div className="vertical">
				<div className="row space top">
					<div className="links" style={{flex: 1}}>
						<Link className="t2" to="/"> Home </Link>
						<Link className="t2" to="/about"> About </Link>
						<Link className="t2" to="/projects"> Projects </Link>
						<Link className="t2" to="/shop"> Shop </Link>
					</div>
					<div className="column bottom trailing">
						<IoCloseOutline className="x" onClick={()=>{this.props.callback(false)}}/>
					</div>
				</div>
				<div className="container column trailing top" style={{flexGrow: 0}}>
					<div className="column top trailing space">
						{contacts}
					</div>
					<div id="socialIcons" className="row bottom leading space">
						{socials}
					</div>
				</div>
			</div>
		</section>
	}
}

export default withContext(withParams(Sidebar));
